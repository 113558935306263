<template>
  <div class="yl-med-history" @click="$emit('click',medh)">
    <div class="__content flex-d-column">
      <ylTitle
        class="__name"
        theme="left"
        barColor="white"
        :title="medh.descs"
      />
      <span class="text-small">
        <i class="yl-icon yl-icon-shizhong"></i>
        {{ medh.medical_time | datetime }}</span
      >
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ylMedHistory",
  props: {
    medh: Object,
  },
};
</script>

<style lang="scss" scoped>
.yl-med-history {
  // background: var(--color-main);
  background: var(--color-item-bg);
  position: relative;
  width: auto;
  padding: var(--padding-base);
  // border: 2px solid var(--color-light);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-medium);
  margin: var(--margin-sm) 0px;
  .create-ics {
    position: absolute;
    right: var(--padding-base);
    top: var(--padding-base);
  }
  .__content {
    display: flex;
    justify-content: space-between;
    padding: var(--padding-base);
    font-size: var(--font-size-lg);
    .__name {
      // color: var(--color-main);
      font-weight: bold;
      padding-bottom: var(--padding-sm);
    }
  }
}
</style>