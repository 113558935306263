var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"yl-title text-large",style:({
    '--title-text-color': _vm.color,
    '--bar-color': _vm.barColor,
    '--bar-width': _vm.barWidth,
    '--bar-height': _vm.barHeight,
    '--bar-offset': _vm.barOffset,
    '--bar-font-size': _vm.size,
  })},[_c('span',{class:'__text-' + _vm.theme},[_vm._v(" "+_vm._s(_vm.title))])])
}
var staticRenderFns = []

export { render, staticRenderFns }